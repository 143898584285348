<ng-container *ngIf="view.data as cart">
  <!-- Order Details -->
  @if (orderDetails !== 'hidden') {
    <xd-list-item icon="hashtag">
      <span xdHeadline *ngIf="cart.number">
        <span [xClip]="cart.number">Order #{{ cart.number }}</span>
      </span>
      <span xdLine>
        <span [xClip]="cart.id">Order ID{{ cart.id }}</span>
      </span>
      <span xdLine>State: {{ 'OrderState.' + cart.state | l }}</span>
      <span xdLine>Source: {{ cart.source }}</span>
      <button mat-icon-button [matMenuTriggerFor]="orderMenu" *ngIf="showOrderMenu">
        <fa-icon icon="ellipsis-v"></fa-icon>
      </button>
    </xd-list-item>
    <mat-menu #orderMenu="matMenu">
      <ng-template matMenuContent>
        <a mat-menu-item [routerLink]="['/admin/orders', cart.id, 'detail']">View Order</a>
      </ng-template>
    </mat-menu>
  }

  <!-- Channel -->
  @if (orderDetails !== 'hidden') {
    <xd-list-item icon="store">
      <span xdHeadline>Channel</span>
      <span xdLine>{{ cart.channel.name }}</span>
      <span xdLine>{{ cart.locale }}</span>
    </xd-list-item>
  }

  <!-- Customer -->
  @if (customerInfo !== 'hidden') {
    <xd-list-item icon="user" *ngIf="cart.user as user">
      <span xdHeadline>Customer</span>
      <span xdLine>{{ user.firstName }} {{ user.lastName }}</span>
      <span xdLine>{{ user.email }}</span>
      <span xdLine *ngIf="user.phone">{{ user.phone }}</span>
      <button
        *ngIf="customerInfo !== 'readonly'"
        mat-icon-button
        [matMenuTriggerFor]="orderUserMenu"
      >
        <fa-icon icon="cog"></fa-icon>
      </button>
    </xd-list-item>
    <xd-list-item icon="user" *ngIf="!cart.user">
      <span xdHeadline>Customer Unassigned</span>
      <button
        *ngIf="customerInfo !== 'readonly'"
        mat-icon-button
        [matMenuTriggerFor]="orderUserMenu"
      >
        <fa-icon icon="cog"></fa-icon>
      </button>
    </xd-list-item>
    <mat-menu #orderUserMenu="matMenu">
      <button mat-menu-item (click)="orderActions.assignCustomer(view)">Assign Customer</button>
      <ng-template matMenuContent *ngIf="cart.user as user">
        <a mat-menu-item [routerLink]="['/admin/users/detail', user.id]">View Customer</a>
      </ng-template>
      <button mat-menu-item (click)="orderActions.removeCustomer(view)">Remove Customer</button>
    </mat-menu>
  }

  <!-- Shipping Address -->
  @if (shippingAddressInfo !== 'hidden') {
    <xd-list-item icon="location-dot" *ngIf="cart.shippingAddress as address">
      <span xdLine>
        <x-address-pill [address]="address" [matMenuTriggerFor]="orderAddressMenu"></x-address-pill>
      </span>
      <button
        *ngIf="shippingAddressInfo !== 'readonly'"
        mat-icon-button
        [matMenuTriggerFor]="orderAddressMenu"
        class="ms-0"
      >
        <fa-icon icon="cog"></fa-icon>
      </button>
    </xd-list-item>
    <xd-list-item icon="shop" *ngIf="cart?.collectionPoint">
      <span xdHeadline>Collection Point:</span>
      <span xdLine>
        {{ cart?.collectionPoint?.name }}
      </span>
    </xd-list-item>
    <xd-list-item icon="location-dot" *ngIf="!cart.shippingAddress">
      <span xdHeadline>Shipping Address Unassigned</span>
      <button
        *ngIf="shippingAddressInfo !== 'readonly'"
        mat-icon-button
        [matMenuTriggerFor]="orderAddressMenu"
        class="address-btn"
      >
        <fa-icon icon="cog"></fa-icon>
      </button>
    </xd-list-item>
    <mat-menu #orderAddressMenu="matMenu">
      <button mat-menu-item (click)="orderActions.updateShippingAddress(view)">
        Update Address
      </button>
      <button mat-menu-item *ngIf="cart.user" (click)="orderActions.assignCustomerAddress(view)">
        Assign Customer Address
      </button>
      <button mat-menu-item *ngIf="cart.user" (click)="orderActions.assignCollectionPoint(view)">
        Assign Collection Point
      </button>
    </mat-menu>
    <xd-list-item icon="map-location-dot">
      <span xdLine>
        <x-pill color="white" *ngIf="cart.shippingAddressGeoRegion">
          {{ cart.shippingAddressGeoRegion.name }}
        </x-pill>
        <x-pill *ngIf="!cart.shippingAddressGeoRegion">Unassigned</x-pill>
      </span>
    </xd-list-item>
  }

  <!-- Shipping Slot -->
  @if (shippingSlotInfo !== 'hidden') {
    <xd-list-item icon="calendar" *ngIf="cart.shipment as shipment">
      <span xdHeadline>Delivery Date</span>
      <span xdLine *ngIf="!shipment.requestedSlotId">Unassigned</span>
      <span xdLine *ngIf="shipment.requestedSlotId">
        {{ shipment.requestedSlotStartAt | date: 'EEE, MMMM d, y' }},
        {{ shipment.requestedSlotStartAt | date: 'shortTime' }}
        -
        {{ shipment.requestedSlotEndAt | date: 'shortTime' }}
      </span>
      <fa-icon
        *ngIf="shipment.requestedSlotId"
        [icon]="shipment.requestedSlotId === shipment.assignedSlotId ? 'check' : 'xmark'"
        [matTooltip]="
          shipment.requestedSlotId === shipment.assignedSlotId
            ? 'Slot Assigned'
            : 'Assigned slot does not match requested slot'
        "
      ></fa-icon>
      <span xdLine *ngIf="shipment.requestedSlotId">{{ shipment.requestedSlotId }}</span>
      <button
        mat-icon-button
        [matMenuTriggerFor]="orderShippingSlotMenu"
        [matMenuTriggerData]="{ $implicit: shipment }"
        *ngIf="shipment.method?.slotRequired"
      >
        <fa-icon icon="cog"></fa-icon>
      </button>
    </xd-list-item>
    <mat-menu #orderShippingSlotMenu="matMenu">
      <ng-template matMenuContent let-shipment>
        <button
          *ngIf="shippingSlotInfo !== 'readonly'"
          mat-menu-item
          (click)="orderActions.assignShippingSlot(view)"
        >
          Assign Shipping Slot
        </button>
      </ng-template>
    </mat-menu>
  }

  <!-- Items -->
  @if (orderItemsInfo !== 'hidden') {
    <mat-divider></mat-divider>
    <xd-expand-item icon="stroopwafel">
      <span xdHeadline>Order Items</span>
      @if (cart.items.length > 0) {
        <table class="mat-typography product-summary-table">
          <tbody>
            <tr *ngFor="let item of cart.items">
              <td style="text-align: left">
                <a
                  [routerLink]="['/admin/inventory/stock-items/detail', item.variant.stockItem?.id]"
                >
                  {{ item.variant.stockItem?.sku }}
                </a>
              </td>
              <td style="text-align: left">
                <a [routerLink]="['/admin/products/detail', item.variant.product.id]">
                  {{ item.productName }}
                </a>
              </td>
              <td>{{ item.quantity }}</td>
            </tr>
          </tbody>
        </table>
      } @else {
        No order items
      }
    </xd-expand-item>
  }

  <!-- Coupon / Referrer -->
  @if (couponReferrerInfo !== 'hidden') {
    <!-- Coupon / Referrer -->
    <xd-list-item icon="code">
      <span xdHeadline>Coupon / Referrer</span>
      <span xdLine>
        {{ cart.couponCode ? 'Coupon: ' + cart.couponCode : 'No Coupon Code' }}
      </span>
      <span xdLine>
        {{ cart.referrerCode ? 'Referrer: ' + cart.referrerCode : 'No Referrer' }}
      </span>
      <button
        mat-icon-button
        [matMenuTriggerFor]="orderCouponMenu"
        *ngIf="['CART', 'ENQUIRY'].includes(cart.state) && couponReferrerInfo !== 'readonly'"
      >
        <fa-icon icon="cog"></fa-icon>
      </button>
    </xd-list-item>
    <mat-menu #orderCouponMenu="matMenu">
      <button mat-menu-item (click)="orderActions.assignReferrer(view)">Assign Referrer</button>
      <button mat-menu-item (click)="orderActions.assignCouponCode(view)">Assign Coupon</button>
    </mat-menu>
  }

  <!-- Checkout Requirements -->
  @if (checkoutRequirementsInfo !== 'hidden') {
    <mat-divider></mat-divider>
    <xd-expand-item icon="cart-xmark">
      <span xdHeadline>Checkout Requirements</span>
      <fa-icon
        xdDescription
        [icon]="cart.checkoutRequirements.canCheckout ? 'check' : 'xmark'"
      ></fa-icon>
      <div
        *ngFor="let requirement of cart.checkoutRequirements.requirements"
        style="display: flex; gap: 12px; align-items: center; padding: 6px 0"
      >
        <fa-icon
          [icon]="requirement.isValid ? 'check' : 'x'"
          [xColor]="requirement.isValid ? 'current' : 'warn'"
        ></fa-icon>
        <div
          class="mat-body-2"
          [matTooltip]="requirement.code"
          [matTooltipShowDelay]="750"
          [xColor]="requirement.isValid ? 'current' : 'warn'"
        >
          {{ requirement.message }}
        </div>
      </div>
    </xd-expand-item>
  }

  <!-- Gift Options -->
  @if (giftOptionsInfo !== 'hidden') {
    <mat-divider></mat-divider>
    <xd-expand-item icon="gift">
      <span xdHeadline>Gift Options</span>
      <fa-icon xdDescription [icon]="cart.isGift ? 'check' : 'xmark'"></fa-icon>
      <div class="formatted-message" *ngIf="cart.isGift && cart.giftMessage; else noGiftMessage">
        {{ cart.giftMessage }}
      </div>
      <ng-template #noGiftMessage>
        <div class="mat-hint">
          {{ cart.isGift ? 'Not a gift' : 'No gift message' }}
        </div>
      </ng-template>
      <x-action-row *ngIf="giftOptionsInfo !== 'readonly'">
        <button mat-flat-button color="accent" (click)="orderActions.updateGiftOptions(view)">
          Update Gift Options
        </button>
      </x-action-row>
    </xd-expand-item>
  }

  <!-- Packing Instructions -->
  @if (packingInstructionsInfo !== 'hidden') {
    <mat-divider></mat-divider>
    <xd-expand-item icon="memo">
      <span xdHeadline>Packing Instructions</span>
      <fa-icon xdDescription [icon]="cart.instructions ? 'check' : 'xmark'"></fa-icon>
      <div class="formatted-message">
        {{ cart.instructions ? cart.instructions : 'No instructions' }}
      </div>
      <x-action-row *ngIf="packingInstructionsInfo !== 'readonly'">
        <button
          mat-flat-button
          color="accent"
          (click)="orderActions.updatePackingInstructions(view)"
        >
          Update Instructions
        </button>
      </x-action-row>
    </xd-expand-item>
  }

  <!-- Fulfillment -->
  @if (fulfilmentInfo !== 'hidden') {
    <mat-divider></mat-divider>
    <xd-expand-item icon="dolly-flatbed" *ngIf="cart.fulfilment as fulfilment">
      <span xdHeadline>Fulfilment</span>
      <x-fulfilment-pill
        xdDescription
        *ngIf="cart.fulfilment; else unassignedFulfilment"
        [fulfilment]="cart.fulfilment"
      ></x-fulfilment-pill>
      <ng-template #unassignedFulfilment>Unassigned</ng-template>
      <table class="mat-typography cart-summary-table">
        <tbody>
          <tr>
            <th>Fulfilment ID</th>
            <td>{{ fulfilment.id }}</td>
          </tr>
          <tr>
            <th>Warehouse</th>
            <td>{{ fulfilment.warehouse?.name ?? 'Unassigned' }}</td>
          </tr>
          <tr>
            <th>Fulfilment State</th>
            <td>{{ fulfilment.state }}</td>
          </tr>
          <tr>
            <th>Fulfilment Date</th>
            <td>{{ (fulfilment.date | date: 'short') ?? '-' }}</td>
          </tr>
          <tr>
            <th>Allocated At</th>
            <td>{{ (fulfilment.allocatedAt | date: 'short') ?? '-' }}</td>
          </tr>
          <tr>
            <th>Packed At</th>
            <td>{{ (fulfilment.packedAt | date: 'short') ?? '-' }}</td>
          </tr>
        </tbody>
      </table>

      <x-action-row *ngIf="!(fulfilmentInfo === 'readonly' || ['NEW'].includes(fulfilment.state))">
        <button
          mat-flat-button
          *ngIf="['RESERVED', 'ALLOCATED', 'UNALLOCATED', 'PACKED'].includes(fulfilment.state)"
          color="warn"
          (click)="orderActions.cancelFulfilment(view)"
        >
          Cancel
        </button>
        <button
          mat-flat-button
          color="accent"
          *ngIf="['RESERVED', 'ALLOCATED'].includes(fulfilment.state)"
          (click)="orderActions.unallocateFulfilment(view)"
        >
          Unallocate
        </button>
        <button
          mat-flat-button
          color="primary"
          *ngIf="['RESERVED', 'UNALLOCATED'].includes(fulfilment.state)"
          (click)="orderActions.allocateFulfilment(view)"
        >
          Allocate
        </button>
        <button
          mat-flat-button
          color="accent"
          *ngIf="['PACKED'].includes(fulfilment.state)"
          (click)="orderActions.unpackFulfilment(view)"
        >
          Unpack
        </button>
        <button
          mat-flat-button
          color="primary"
          *ngIf="['ALLOCATED', 'UNALLOCATED'].includes(fulfilment.state)"
          (click)="orderActions.packFulfilment(view)"
        >
          Pack
        </button>
      </x-action-row>
    </xd-expand-item>
  }

  <!-- Shipment -->
  @if (shipmentInfo !== 'hidden' && cart.shipment; as shipment) {
    <mat-divider></mat-divider>
    <xd-expand-item icon="truck">
      <span xdHeadline>Shipment</span>
      <x-shipment-pill
        xdDescription
        *ngIf="cart.shipment; else unassignedShipment"
        [shipment]="cart.shipment"
      ></x-shipment-pill>
      <ng-template #unassignedShipment>Unassigned</ng-template>
      <table class="mat-typography cart-summary-table mb-2">
        <tbody>
          <tr>
            <th>Shipment ID</th>
            <td>{{ shipment.id }}</td>
          </tr>
          <tr>
            <th>Shipping Method</th>
            <td>
              <a [routerLink]="['/admin/logistics/shipping-methods/detail', shipment.method?.id]">
                {{ shipment.method?.name ?? 'Unassigned' }} [{{ shipment.method?.id }}]
              </a>
            </td>
          </tr>
          <tr>
            <th>Shipping State</th>
            <td>{{ shipment.state }}</td>
          </tr>
          <tr>
            <th>Description</th>
            <td>{{ shipment.description }}</td>
          </tr>
          <tr>
            <th>Reference</th>
            <td>{{ shipment.reference }}</td>
          </tr>
          <tr>
            <th>Requested Slot</th>
            <td>
              <span *ngIf="shipment.requestedSlotId">
                {{ [shipment.requestedSlotStartAt, shipment.requestedSlotEndAt] | dateRange }}
                <br />
                {{ shipment.requestedSlotId }}
              </span>
            </td>
          </tr>
          <tr>
            <th>Reserved Slot</th>
            <td>
              {{ shipment.assignedSlotId }}
            </td>
          </tr>
          <tr *ngIf="shipment.slot as slot">
            <th>Designated Slot</th>
            <td>
              {{ slot.id }}
              <br />
              {{ ([slot.startAt, slot.endAt] | dateRange: 'long') ?? '-' }}
            </td>
          </tr>
          <tr *ngIf="shipment.driver">
            <th>Driver</th>
            <td>
              {{ shipment.driver }}
            </td>
          </tr>
          <tr>
            <th>Total Weight</th>
            <td>{{ shipment.totalWeight }}g</td>
          </tr>
          <tr>
            <th>Labelled At</th>
            <td>{{ (shipment.labeledAt | date: 'short') ?? '-' }}</td>
          </tr>
          <tr>
            <th>Shipped At</th>
            <td>{{ (shipment.shippedAt | date: 'short') ?? '-' }}</td>
          </tr>
          <tr>
            <th>Completed At</th>
            <td>{{ (shipment.completedAt | date: 'short') ?? '-' }}</td>
          </tr>
          <tr>
            <th>Created At</th>
            <td>{{ (shipment.createdAt | date: 'short') ?? '-' }}</td>
          </tr>
          <tr>
            <th>Updated At</th>
            <td>{{ (shipment.updatedAt | date: 'short') ?? '-' }}</td>
          </tr>
        </tbody>
      </table>
      <x-action-row *ngIf="shipmentInfo !== 'readonly' && !['NEW'].includes(shipment.state)">
        <button
          *ngIf="['WAYBILL_REQUIRED', 'PENDING', 'SHIPPED'].includes(shipment.state)"
          mat-flat-button
          color="warn"
          (click)="orderActions.cancelShipment(view)"
        >
          Cancel
        </button>
        <button
          *ngIf="shipment.trackingUrl"
          mat-flat-button
          color="accent"
          (click)="orderActions.openInNewTab(shipment.trackingUrl)"
        >
          Track
        </button>
        <button mat-flat-button color="accent" (click)="orderActions.reconShipment(view)">
          Recon
        </button>
        <button
          mat-flat-button
          color="primary"
          (click)="orderActions.submitShipmentWaybill(view)"
          *ngIf="shipment.state === 'WAYBILL_REQUIRED'"
        >
          Submit Waybill
        </button>
        <button
          mat-flat-button
          color="primary"
          (click)="orderActions.shipShipment(view)"
          *ngIf="shipment.state === 'PENDING'"
        >
          Ship
        </button>
        <button
          mat-flat-button
          color="primary"
          *ngIf="shipment.state === 'SHIPPED'"
          (click)="orderActions.deliverShipment(view)"
        >
          Deliver
        </button>
      </x-action-row>
    </xd-expand-item>
  }

  <!-- Payments -->
  @if (paymentInfo !== 'hidden') {
    <mat-divider></mat-divider>
    <xd-expand-item icon="credit-card">
      <span xdHeadline>Payment</span>
      <x-order-payment-pill xdDescription [order]="cart"></x-order-payment-pill>
      <ng-container *ngIf="cart.payments.length === 0 && cart.walletTransactions.length === 0">
        No payments or wallet transactions associated with this order
      </ng-container>
      <ng-container *ngFor="let payment of cart.payments; let index = index">
        <mat-divider class="mb-3" *ngIf="index > 0"></mat-divider>
        <table class="mat-typography cart-summary-table">
          <tbody>
            <tr>
              <th>Payment ID</th>
              <td>{{ payment.id }}</td>
            </tr>
            <tr>
              <th>Payment State</th>
              <td>{{ payment.state }}</td>
            </tr>
            <tr>
              <th>Payment Amount</th>
              <td>{{ (payment.amount | money: payment.currency) ?? '-' }}</td>
            </tr>
            <tr>
              <th>Refunded Amount</th>
              <td>{{ (payment.refundedAmount | money: payment.currency) ?? '-' }}</td>
            </tr>
            <tr>
              <th>Refunded At</th>
              <td>{{ (payment.refundedAt | date: 'short') ?? '-' }}</td>
            </tr>
            <tr>
              <th>Description</th>
              <td>{{ payment.description }}</td>
            </tr>
            <tr *ngIf="payment.errorDescription">
              <th>Error</th>
              <td>{{ payment.errorDescription }}</td>
            </tr>
            <tr>
              <th>Reference</th>
              <td>{{ payment.reference }}</td>
            </tr>
            <tr>
              <th>Payment Method</th>
              <td>{{ payment.method.name }}</td>
            </tr>
            <tr>
              <th>Payment Details Saved</th>
              <td>{{ payment.save ? 'Yes' : 'No' }}</td>
            </tr>
            <tr>
              <th>Created At</th>
              <td>{{ payment.createdAt | date: 'short' }}</td>
            </tr>
            <tr>
              <th>Updated At</th>
              <td>{{ payment.updatedAt | date: 'short' }}</td>
            </tr>
            <tr>
              <th>Finalized At</th>
              <td>{{ (payment.finalizedAt | date: 'short') ?? '-' }}</td>
            </tr>
            <tr>
              <th>Public Attributes</th>
              <td>
                <pre style="max-width: 300px; overflow-x: auto; text-align: left">{{
                  (payment.publicAttributes | json) ?? '-'
                }}</pre>
              </td>
            </tr>
            <tr>
              <td colspan="2">
                <x-action-row *ngIf="paymentInfo !== 'readonly'">
                  <button
                    mat-flat-button
                    color="primary"
                    [disabled]="payment.state !== 'PROCESSING'"
                    (click)="orderActions.openPaymentDialog(payment)"
                  >
                    <fa-icon icon="money-bill"></fa-icon>
                    &nbsp;Open
                  </button>
                  <button
                    mat-flat-button
                    color="warn"
                    [disabled]="!payment.canRefund || payment.state !== 'COMPLETED'"
                    (click)="orderActions.refundPayment(view, payment)"
                  >
                    <fa-icon icon="hand-holding-dollar"></fa-icon>
                    &nbsp;Refund
                  </button>
                </x-action-row>
              </td>
            </tr>
          </tbody>
        </table>
      </ng-container>
      <ng-container *ngFor="let transaction of cart.walletTransactions; let index = index">
        <mat-divider class="mb-3 mt-3" *ngIf="index > 0"></mat-divider>
        <table class="mat-typography cart-summary-table">
          <tbody>
            <tr>
              <th>Transaction ID</th>
              <td>{{ transaction.id }}</td>
            </tr>
            <tr>
              <th>Transaction State</th>
              <td>{{ transaction.state }}</td>
            </tr>
            <tr>
              <th>Transaction Description</th>
              <td>{{ transaction.description }}</td>
            </tr>
            <tr>
              <th>Created At</th>
              <td>{{ transaction.createdAt | date: 'short' }}</td>
            </tr>
            <tr>
              <th>Updated At</th>
              <td>{{ transaction.updatedAt | date: 'short' }}</td>
            </tr>
            <tr>
              <th>Finalized At</th>
              <td>{{ transaction.finalizedAt | date: 'short' }}</td>
            </tr>
            <tr>
              <th>Amount</th>
              <td>{{ transaction.amount | money: transaction.wallet.currency }}</td>
            </tr>
          </tbody>
        </table>
      </ng-container>
      <x-action-row *ngIf="paymentInfo !== 'readonly'">
        <button
          mat-flat-button
          color="warn"
          [disabled]="!['PARTIALLY_PAID', 'PAID', 'PARTIALLY_REFUNDED'].includes(cart.paymentState)"
          (click)="orderActions.refundToWallet(view)"
        >
          Refund to Wallet
        </button>
        <button
          mat-flat-button
          color="primary"
          [disabled]="!['AWAITING_PAYMENT', 'PARTIALLY_PAID'].includes(cart.paymentState)"
          [matMenuTriggerFor]="createPaymentMenu"
        >
          Create Payment
        </button>
      </x-action-row>
    </xd-expand-item>
  }

  <!-- Promotions -->
  @if (promotionInfo !== 'hidden') {
    <mat-divider></mat-divider>
    <xd-expand-item icon="bullhorn">
      <span xdHeadline>Promotions</span>
      <span xdDescription>{{ cart.promotions.length }}</span>
      @if (cart.promotions.length > 0) {
        <table class="mat-typography cart-summary-table">
          <tbody>
            <tr *ngFor="let promotion of cart.promotions">
              <th>
                <a [routerLink]="['/admin/promotions/form', promotion.id]">{{ promotion.name }}</a>
              </th>
              <td>ID {{ promotion.id }}</td>
            </tr>
          </tbody>
        </table>
      } @else {
        No promotions applied
      }
    </xd-expand-item>
  }

  <!-- Stock -->
  @if (stockInfo !== 'hidden') {
    <mat-divider></mat-divider>
    <xd-expand-item icon="shelves">
      <span xdHeadline>Stock Units</span>
      <span xdDescription>{{ getTotalItemUnitCount(cart) }}</span>
      @if (getTotalItemUnitCount(cart) > 0) {
        <table class="mat-typography cart-summary-table">
          <tbody>
            <ng-container *ngFor="let item of cart.items; trackBy: trackById">
              <tr *ngFor="let unit of item.units; trackBy: trackById">
                <th>
                  <a
                    [routerLink]="[
                      '/admin/inventory/stock-items/detail',
                      item.variant.stockItem?.id
                    ]"
                  >
                    {{ unit.stockItem?.sku }}
                    <br />
                    <span class="mat-hint" style="font-weight: normal">
                      {{ unit.stockItem?.name }}
                    </span>
                  </a>
                </th>
                <td>{{ unit.stockLotRef }}</td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      } @else {
        No stock units assigned
      }
    </xd-expand-item>
  }

  <!-- Adjustments -->
  @if (adjustmentInfo !== 'hidden') {
    <mat-divider></mat-divider>
    <xd-expand-item icon="plus-minus">
      <span xdHeadline>Adjustments</span>
      <span xdDescription>
        @if (getAdjustmentCount(cart); as count) {
          {{ count > 0 ? count : '' }}
        }
      </span>
      @if (hasAdjustments(cart)) {
        <table class="mat-typography cart-summary-table">
          <tbody>
            @for (adjustment of cart.adjustments; track adjustment.id) {
              <tr>
                <th class="mat-body-2">
                  <span class="mat-subtitle-2">
                    {{ 'OrderAdjustmentType.' + adjustment.type | l }} Order Adjustment
                  </span>
                  <br />
                  <span class="mat-body-2">
                    {{ adjustment.label }}
                  </span>
                </th>
                <td valign="bottom">
                  {{ adjustment.amount | money: cart.currency }}
                </td>
              </tr>
            }
            @for (item of cart.items; track item.id) {
              @for (adjustment of item.adjustments; track adjustment.id) {
                <tr>
                  <th class="mat-body-2">
                    <span class="mat-subtitle-2">
                      {{ 'OrderAdjustmentType.' + adjustment.type | l }} Item Adjustment
                    </span>
                    <br />
                    <span class="mat-hint">
                      {{ item.productName }}
                    </span>
                    <br />
                    <span class="mat-body-2">
                      {{ adjustment.label }}
                    </span>
                  </th>
                  <td valign="bottom">{{ adjustment.amount | money: cart.currency }}</td>
                </tr>
              }
            }
          </tbody>
        </table>
      } @else {
        No adjustments applied
      }
      <x-action-row *ngIf="adjustmentInfo !== 'readonly'">
        <button mat-flat-button color="accent" (click)="orderActions.adjustOrder(view)">
          Adjust Order
        </button>
      </x-action-row>
    </xd-expand-item>
  }

  <!-- Vouchers -->
  @if (voucherInfo !== 'hidden' && cart.vouchers.length) {
    <mat-divider></mat-divider>
    <xd-expand-item icon="ticket-simple">
      <span xdHeadline>Vouchers</span>
      <span xdDescription>{{ cart.vouchers.length }}</span>
      <table
        class="mat-typography cart-summary-table"
        *ngFor="let voucher of cart.vouchers; trackBy: trackById"
      >
        <tbody>
          <tr>
            <th>ID</th>
            <td>{{ voucher.id }}</td>
          </tr>
          <tr>
            <th>Code</th>
            <td>{{ voucher.code }}</td>
          </tr>
          <tr>
            <th>Value</th>
            <td>{{ voucher.value | money: voucher.currency }}</td>
          </tr>
        </tbody>
      </table>
    </xd-expand-item>
  }

  <!-- Order info -->
  @if (orderInfo !== 'hidden') {
    <mat-divider></mat-divider>
    <xd-expand-item icon="info-circle">
      <span xdHeadline>Order Info</span>
      <table class="mat-typography cart-summary-table">
        <tbody>
          <tr>
            <th>Checkout At:</th>
            <td>{{ (cart.checkoutAt | date: 'short') ?? '-' }}</td>
          </tr>
          <tr>
            <th>Created At:</th>
            <td>{{ (cart.createdAt | date: 'short') ?? '-' }}</td>
          </tr>
          <tr>
            <th>Updated At:</th>
            <td>{{ (cart.updatedAt | date: 'short') ?? '-' }}</td>
          </tr>
          <tr>
            <th>Quoted At:</th>
            <td>{{ (cart.quotedAt | date: 'short') ?? '-' }}</td>
          </tr>
          <tr>
            <th>Canceled At:</th>
            <td>{{ (cart.canceledAt | date: 'short') ?? '-' }}</td>
          </tr>
          <tr>
            <th>Authored By:</th>
            <td>{{ cart.authorUser?.email ?? '-' }}</td>
          </tr>
          <tr *ngIf="cart.originalOrder">
            <th>Original Order:</th>
            <td>
              <a
                [routerLink]="['/admin/orders', cart.originalOrder.id, 'detail']"
                matTooltip="View Original Order"
              >
                ID{{ cart.originalOrder.id }}
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </xd-expand-item>
  }

  <!-- Subscription Info -->
  @if (subscriptionInfo !== 'hidden' && cart.subscriptionEntry) {
    <mat-divider></mat-divider>
    <xd-expand-item icon="id-card">
      <span xdHeadline>Subscription</span>
      <table class="mat-typography subscription-table">
        <tbody>
          <tr>
            <th>Subscriber</th>
            <td>
              <x-subscriber-pill
                [subscriber]="cart.subscriptionEntry.subscriber"
              ></x-subscriber-pill>
            </td>
          </tr>
          <tr>
            <th>Subscription</th>
            <td>{{ cart.subscriptionEntry.run.variant.name }}</td>
          </tr>
          <tr>
            <th>Subscription Run</th>
            <td>{{ cart.subscriptionEntry.run.name }}</td>
          </tr>
          <tr *ngIf="cart.subscriptionEntry.errorMessage">
            <th>Error Message</th>
            <td>{{ cart.subscriptionEntry.errorMessage }}</td>
          </tr>
          <tr>
            <th>Created At</th>
            <td>{{ cart.subscriptionEntry.createdAt | date: 'short' }}</td>
          </tr>
          <tr>
            <th>Updated At</th>
            <td>{{ cart.subscriptionEntry.updatedAt | date: 'short' }}</td>
          </tr>
        </tbody>
      </table>
    </xd-expand-item>
  }

  <!-- Sage Info -->
  @if (sageInfo !== 'hidden') {
    <mat-divider></mat-divider>
    <x-sage-order-sync-panel
      *ngIf="cart.number as number"
      [number]="number"
    ></x-sage-order-sync-panel>
  }
  <mat-divider></mat-divider>
</ng-container>

<mat-menu #createPaymentMenu>
  <button mat-menu-item (click)="orderActions.manualPayment(view)">Manual Payment</button>
  <button mat-menu-item (click)="orderActions.pay(view)">
    Credit Card / EFT / Voucher Payment
  </button>
</mat-menu>
