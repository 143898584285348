import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CommonValidator } from '@x/common/form';
import { PaymentMethodService, PaymentProviderService } from '@x/ecommerce/domain-client';
import { ChannelContext } from '@x/ecommerce/domain-data';
import { firstValueFrom, lastValueFrom } from 'rxjs';

export interface PaymentMethodFormDialogComponentData {
  paymentMethodId?: number;
}
export interface PaymentMethodFormDialogComponentResult {
  data?: { id: number; name: string; code: string };
}

@Component({
  selector: 'x-payment-method-form-dialog',
  templateUrl: 'payment-method-form-dialog.component.html',
})
export class PaymentMethodFormDialogComponent implements OnInit {
  providers$ = this.paymentProviders.getAllProviders();
  channels$ = this.channelContext.getAllChannels();

  providerControl = new UntypedFormControl(null, [Validators.required]);
  channelsControl = new UntypedFormControl(null, []);

  formGroup = new UntypedFormGroup({
    name: new UntypedFormControl(null, [Validators.required, Validators.maxLength(64)]),
    channels: this.channelsControl,
    provider: this.providerControl,
    providerConfig: new UntypedFormControl(null, [Validators.required, CommonValidator.json()]),
  });

  constructor(
    private paymentProviders: PaymentProviderService,
    private paymentMethodService: PaymentMethodService,
    private channelContext: ChannelContext,
    public dialog: MatDialogRef<
      PaymentMethodFormDialogComponent,
      PaymentMethodFormDialogComponentResult
    >,
    @Inject(MAT_DIALOG_DATA)
    private dialogData: PaymentMethodFormDialogComponentData,
  ) {}

  async ngOnInit() {
    if (this.dialogData.paymentMethodId) {
      // existing
      const paymentMethod = await firstValueFrom(
        this.paymentMethodService.fetchDetail(this.dialogData.paymentMethodId),
      );

      this.formGroup.setValue({
        name: paymentMethod.name,
        provider: paymentMethod.provider,
        channels: paymentMethod.channels.map((c) => c.code),
        providerConfig: JSON.stringify(paymentMethod.providerConfig),
      });
    } else {
      // noop
    }
  }

  async submit() {
    this.formGroup.updateValueAndValidity();

    if (this.formGroup.invalid) return;

    const form = this.formGroup.value;

    if (this.dialogData.paymentMethodId) {
      // update
      const data = await lastValueFrom(
        this.paymentMethodService.update({
          id: this.dialogData.paymentMethodId,
          name: form.name,
          provider: form.provider,
          channelCodes: form.channels,
          providerConfig: form.providerConfig ? JSON.parse(form.providerConfig) : null,
        }),
      );
      this.dialog.close({ data });
    } else {
      // create
      const data = await lastValueFrom(
        this.paymentMethodService.create({
          name: form.name,
          provider: form.provider,
          channelCodes: form.channels,
          providerConfig: form.providerConfig ? JSON.parse(form.providerConfig) : null,
        }),
      );

      if (!data?.id) {
        // TODO: handle error better
        console.log('Error creating payment method', data);
        this.dialog.close({ data });
      } else {
        this.dialog.close({ data });
      }
    }
  }
}
