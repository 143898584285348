import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { WindowRef } from '@x/common/browser';
import { DataTableView } from '@x/common/data';
import { PromptDialogService } from '@x/dashboard/dialog';
import { AuthContextService } from '@x/ecommerce-admin/app/auth/services/auth-context.service';
import { AddressDialogService } from '@x/ecommerce-admin/app/core/services/address-dialog.service';
import { ShipmentFormDialogData } from '@x/ecommerce-admin/app/logistics/components/shipment-form-dialog/shipment-form-dialog.component';
import { ShipmentDialogService } from '@x/ecommerce-admin/app/logistics/services/shipment-dialog.service';
import { PaymentsDialogService } from '@x/ecommerce-admin/app/payments/services/payment-dialog.service';
import {
  CartService,
  IOrderRowObject,
  OrderService,
  PaymentService,
  ShipmentService,
} from '@x/ecommerce/domain-client';
import { StockCategoryItemCollectionProvider } from '@x/ecommerce/domain-data';
import {
  AddressAssignment,
  AddressInput,
  OrderFilterInput,
  OrderState,
  ShipmentState,
} from '@x/schemas/ecommerce';
import { firstValueFrom, lastValueFrom } from 'rxjs';
import { OrderDialogService } from '../../services/order-dialog.service';

@Component({
  selector: 'x-order-table',
  templateUrl: 'order-table.component.html',
  styleUrls: ['order-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'x-order-table',
  },
})
export class OrderTableComponent implements OnInit {
  @Input()
  view: DataTableView<IOrderRowObject, OrderFilterInput, any, number>;

  constructor(
    public authContext: AuthContextService,
    private orderDialogService: OrderDialogService,
    private orderService: OrderService,
    private window: WindowRef,
    private snackbar: MatSnackBar,
    private shipmentService: ShipmentService,
    private changeRef: ChangeDetectorRef,
    private cartService: CartService,
    private shipmentDialogService: ShipmentDialogService,
    private paymentService: PaymentService,
    private paymentDialogs: PaymentsDialogService,
    private promptDialogService: PromptDialogService,
    private readonly addressDialogService: AddressDialogService,
  ) {}

  ngOnInit(): void {
    this.view.stateChanges().subscribe(() => this.changeRef.markForCheck());
  }

  async cancelBulk() {
    let result = await lastValueFrom(this.orderDialogService.openOrderCancelDialog());
    if (!result) return;

    this.view
      .mutateEachSelectedBatched((orderId) => this.orderService.cancel(orderId), {
        label: 'Cancel Order',
      })
      .subscribe();
  }

  async processBulk() {
    let result = await lastValueFrom(this.orderDialogService.openOrderProcessConfirmation());
    if (!result) return;

    this.view
      .mutateEachSelectedBatched((id) => this.cartService.process(id), {
        label: 'Process Order',
      })
      .subscribe({
        next: (operations) => {
          operations.forEach((operation) => {
            const snackbarMessage = operation.isErrorState()
              ? `${operation.state.error.title} ${operation.state.error.message}`
              : 'Bulk process completed successfully!';

            this.snackbar
              .open(snackbarMessage, 'Dismiss', {
                duration: 10000,
              })
              .onAction()
              .subscribe(() => {});
          });
        },
      });
  }

  assignCustomer(orderId: number, userId?: number) {
    this.orderDialogService
      .openAssignCustomerDialog(userId)
      .afterClosed()
      .subscribe((res) => {
        if (res?.assign) {
          this.view
            .mutateRow(
              orderId,
              (orderId) =>
                this.orderService.assignOrderCustomer({ orderId, userId: Number(res.value) }),
              { label: 'Assigning Customer' },
            )
            .subscribe();
        }
      });
  }

  assignCustomerBulk() {
    this.orderDialogService
      .openAssignCustomerDialog()
      .afterClosed()
      .subscribe((res) => {
        if (res?.assign) {
          this.view
            .mutateEachSelectedBatched(
              (orderId) =>
                this.orderService.assignOrderCustomer({ orderId, userId: Number(res.value) }),
              { label: 'Assign Order Customer' },
            )
            .subscribe();
        }
      });
  }

  unallocateFulfilmentBulk() {
    this.view
      .mutateEachSelectedBatched((orderId) => this.orderService.unallocateFulfilment(orderId), {
        label: 'Unallocate Order',
      })
      .subscribe();
  }

  allocateFulfilmentBulk() {
    this.view
      .mutateEachSelectedBatched((orderId) => this.orderService.allocateFulfilment(orderId), {
        label: 'Allocate Order',
      })
      .subscribe();
  }

  packFulfilmentBulk() {
    this.view
      .mutateEachSelectedBatched((orderId) => this.orderService.packFulfilment(orderId), {
        label: 'Pack Order',
      })
      .subscribe();
  }

  unpackFulfilmentBulk() {
    this.view
      .mutateEachSelectedBatched((orderId) => this.orderService.unpackFulfilment(orderId), {
        label: 'Unpack Order',
      })
      .subscribe();
  }

  async shipShipmentBulk() {
    const result = await this.orderDialogService.openOrderShipConfirmation().toPromise();
    if (!result) return;
    this.view
      .mutateEachSelectedBatched(
        (orderId) => this.orderService.shipShipment(orderId, result.sendNotification),
        { label: 'Ship Order' },
      )
      .subscribe();
  }

  async cancelShipmentBulk() {
    const result = await this.orderDialogService.openOrderShipmentCancelConfirmation().toPromise();
    if (!result) return;
    this.view
      .mutateEachSelectedBatched((orderId) => this.orderService.cancelShipment(orderId), {
        label: 'Cancel Order Shipment',
      })
      .subscribe();
  }

  async deliverShipmentBulk() {
    const result = await firstValueFrom(this.orderDialogService.openOrderDeliverConfirmation());
    if (!result) return;

    return this.view
      .mutateEachSelectedBatched((orderId) => this.orderService.deliverShipment(orderId))
      .subscribe();
  }

  submitWaybillBulk() {
    this.view
      .mutateEachSelectedBatched((orderId) => this.orderService.submitShipmentWaybill(orderId), {
        label: 'Submitting Waybill',
      })
      .subscribe();
  }

  printManifestBulk() {
    this.promptDialogService
      .confirm({
        title: 'Print All Selected Orders',
        message: 'Printing the orders will mark the orders as printed.',
      })
      .subscribe((result) => {
        if (!result) return;
        this.view
          .mutateSelected((ids) => this.orderService.printOrderManifest({ ids }))
          .subscribe((state) => {
            if (state.isSuccessState()) {
              const url = state.data.url;
              this.snackbar
                .open(`Order manifest PDF is ready`, 'Open', { duration: 10000 })
                .onAction()
                .subscribe(() => this.window.openNewTab(url));
            }
          });
      });
  }

  printManifestV2Bulk() {
    this.promptDialogService
      .confirm({
        title: 'Print All Selected Orders',
        message: 'Printing the orders will mark the orders as printed.',
      })
      .subscribe((result) => {
        if (!result) return;
        this.view
          .mutateSelected((ids) => this.orderService.printOrderManifestV2({ ids }))
          .subscribe((state) => {
            if (state.isSuccessState()) {
              const url = state.data.url;
              this.snackbar
                .open(`Order manifest PDF is ready`, 'Open', { duration: 10000 })
                .onAction()
                .subscribe(() => this.window.openNewTab(url));
            }
          });
      });
  }

  printOrderGiftMessageBulk() {
    this.view
      .mutateSelected((ids) => this.orderService.printOrderGiftMessage({ ids }))
      .subscribe((state) => {
        if (state.isSuccessState()) {
          const url = state.data.url;
          this.snackbar
            .open(`Order gift message PDF is ready`, 'Open', { duration: 10000 })
            .onAction()
            .subscribe(() => this.window.openNewTab(url));
        }
      });
  }

  printTaxInvoiceBulk() {
    this.view
      .mutateSelected((ids) => this.orderService.printOrderTaxInvoice({ ids }))
      .subscribe((state) => {
        if (state.isSuccessState()) {
          const url = state.data.url;
          this.snackbar
            .open(`Order invoice PDF is ready`, 'Open', { duration: 10000 })
            .onAction()
            .subscribe(() => this.window.openNewTab(url));
        }
      });
  }

  printShipmentLabelBulk() {
    this.view
      .mutateSelected((ids) => this.orderService.printOrderShipmentLabels({ orderIds: ids }))
      .subscribe((state) => {
        if (state.isSuccessState()) {
          const url = state.data.url;
          this.snackbar
            .open(`Order shipment label PDF is ready`, 'Open', { duration: 10000 })
            .onAction()
            .subscribe(() => this.window.openNewTab(url));
        }
      });
  }

  async printRouteManifestBulk() {
    const result = await firstValueFrom(
      this.promptDialogService
        .autocomplete({
          provider: StockCategoryItemCollectionProvider,
          multiple: true,
          title: 'Filter categories?',
          message: 'Filter categories or export all categories.',
        })
        .afterClosed(),
    );

    if (result && result.assign) {
      this.view
        .mutateSelected((ids) =>
          this.orderService.printRouteManifest(
            { ...this.view.getQuery().filter, ids },
            { tagIds: <any>result.value },
          ),
        )
        .subscribe((state) => {
          if (state.isSuccessState()) {
            const url = state.data.url;
            this.snackbar
              .open(`Route manifest PDF is ready`, 'Open', { duration: 10000 })
              .onAction()
              .subscribe(() => this.window.openNewTab(url));
          }
        });
    }
  }

  async printRouteSummaryBulk() {
    const result = await firstValueFrom(
      this.promptDialogService.confirm({
        title: 'Print Route Summary for Selected Orders',
      }),
    );

    if (result) {
      this.view
        .mutateSelected((ids) =>
          this.orderService.printRouteSummary({ ...this.view.getQuery().filter, ids }, {}),
        )
        .subscribe((state) => {
          if (state.isSuccessState()) {
            const url = state.data.url;
            this.snackbar
              .open(`Route manifest PDF is ready`, 'Open', { duration: 10000 })
              .onAction()
              .subscribe(() => this.window.openNewTab(url));
          }
        });
    }
  }

  async updateTagsBulk(add = true) {
    const res = await this.orderDialogService.openOrderTagDialog(add).afterClosed().toPromise();

    if (res && res.assign) {
      await this.view
        .mutateEachSelected((orderId) =>
          add
            ? this.orderService.addTags({ orderId, tagIds: res.value })
            : this.orderService.removeTags({ orderId, tagIds: res.value }),
        )
        .toPromise();
    }
  }

  async sendBulkEmail(emailType: string) {
    switch (emailType) {
      case 'orderShippedEmail':
        await this.notifyBulk(this.orderService.sendOrderShippedEmail.bind(this.orderService));
        break;
      case 'requestPaymentEmail':
        await this.notifyBulk(this.orderService.sendRequestPaymentEmail.bind(this.orderService));
        break;
      default:
        break;
    }
  }

  async notifyBulk(email: any) {
    const result = await firstValueFrom(
      this.orderDialogService.openOrderNotifyConfirmationDialog(),
    );

    if (!result) return;

    const emails = await firstValueFrom(
      this.view.mutateEachSelected((orderId) => {
        return email(orderId);
      }),
    );

    if (!emails) return;

    let notsent = [];
    emails.forEach((email) => {
      if (!email.data?.sent) notsent.push(email.data);
    });

    this.snackbar.open(`${emails.length - notsent.length} emails successfully sent`, 'ok', {
      duration: 10000,
    });
  }

  async createParcel(orderId: number) {
    const result = await firstValueFrom(
      this.orderDialogService.openOrderCreateParcelDialog().afterClosed(),
    );

    if (!result) return;

    this.view
      .mutateRow(orderId, (orderId) =>
        this.shipmentService.createOrderShipmentParcel({ orderId: Number(orderId), ...result }),
      )
      .subscribe();
  }

  async openFulfillmentIntervalDialog() {
    if (!this.view.selected) return;

    const firstOrderId = this.view.selected.at(0);

    const result = await firstValueFrom(
      this.orderDialogService
        .openFulfilmentIntervalDialog({ orderId: Number(firstOrderId) })
        .afterClosed(),
    );

    if (!result) return;

    if (result.interval?.slotAvailability?.slotId) {
      const slotId = result.interval.slotAvailability.slotId;

      this.view
        .mutateSelectedBatched(([orderId]) =>
          this.orderService.requestOrderShipmentSlot({
            orderId,
            slotId,
          }),
        )
        .subscribe();
    }
  }

  async checkout() {
    if (!this.view.selected) return;

    const orders: IOrderRowObject[] = this.view.items.filter((i) =>
      this.view.selected.includes(i.id),
    );

    const nonCartOrders = orders.filter((o) => o.state !== OrderState.Cart);

    if (nonCartOrders.length > 0) {
      this.snackbar.open(`Not all selected orders in cart state`, 'OK', { duration: 5000 });
      return;
    }

    const res = await firstValueFrom(this.orderDialogService.openCheckoutConfirmation());
    if (!res || !res.result) {
      return;
    }

    return this.view
      .mutateSelectedBatched((orderIds) =>
        this.cartService.checkout({
          orderId: orderIds[0],
          orderNeverExpires: res.checked,
        }),
      )
      .subscribe();
  }

  async openShipmentFormDialog() {
    if (!this.view.selected) return;

    const orders: IOrderRowObject[] = this.view.items.filter((i) =>
      this.view.selected.includes(i.id),
    );

    if (orders.length === 0) return;

    const { method, slot } = orders[0].shipment || {};

    const methodMismatch = orders.find((o) => o.shipment?.method?.id !== method?.id);

    if (methodMismatch) {
      this.snackbar.open(`Not all selected orders are using the same shipping method`, 'OK', {
        duration: 5000,
      });
      return;
    }

    const data: ShipmentFormDialogData = {
      methodId: method?.id,
      orderId: orders[0].id,
      isRequested: true,
      slotId: slot?.id,
    };

    const result = await firstValueFrom(
      this.shipmentDialogService.openShipmentFormDialog(data).afterClosed(),
    );

    if (!result) return;

    this.view
      .mutateSelectedBatched(([orderId]) =>
        this.orderService.rescheduleOrderShipment({
          orderId,
          methodId: result.methodId,
          slotId: result.slotId,
        }),
      )
      .subscribe();
  }

  async manualPayment() {
    if (!this.view.selected) return;

    const orders: IOrderRowObject[] = this.view.items.filter((i) =>
      this.view.selected.includes(i.id),
    );

    if (orders.length === 0) return;

    const nonQuotedOrders = orders.filter((o) => o.state !== OrderState.Quoted);

    if (nonQuotedOrders.length > 0) {
      this.snackbar.open(`All order must be in Quoted state`, 'OK', { duration: 5000 });
      return;
    }

    const { currency } = orders[0];

    const result = await firstValueFrom(
      this.paymentDialogs.openManualPaymentDialog({ currency }).afterClosed(),
    );

    if (!result) return;

    return this.view
      .mutateSelectedBatched(([orderId]) =>
        this.paymentService.createManualPayment({
          orderId,
          reference: `ID${orderId}-${result.reference}`,
          description: result.description,
        }),
      )
      .subscribe();
  }

  exportSelected(format?: string) {
    this.view
      .mutateSelected((ids) =>
        this.orderService.printOrderSpreadsheet({ ...this.view.getQuery().filter, ids }, format),
      )
      .subscribe((state) => {
        if (state.isSuccessState()) {
          const url = state.data.url;

          this.snackbar
            .open(`Order Records are ready`, 'Download', { duration: 10000 })
            .onAction()
            .subscribe(() => this.window.openNewTab(url));
        }
      });
  }

  async reconShipmentsBulk() {
    if (!this.view.selected) return;

    const orders: IOrderRowObject[] = this.view.items.filter((i) =>
      this.view.selected.includes(i.id),
    );

    if (orders.length === 0) return;

    const pendingAndShippedOrderShipments = orders.filter(
      (o) =>
        o.shipment?.state === ShipmentState.Pending || o.shipment?.state === ShipmentState.Shipped,
    );

    if (pendingAndShippedOrderShipments.length === 0) {
      this.snackbar.open(`All order shipments must be in Pending or Shipped states`, 'OK', {
        duration: 5000,
      });
      return;
    }

    const result = await firstValueFrom(this.orderDialogService.openReconConfirmationDialog());

    if (!result) return;

    return this.view
      .mutateSelectedBatched(([orderId]) => this.orderService.reconShipment(orderId))
      .subscribe();
  }

  exportDpdSpreadsheet(format?: string) {
    this.view
      .mutateSelected((ids) => this.orderService.printDpdSpreadsheet({ ids }, format))
      .subscribe((state) => {
        if (state.isSuccessState()) {
          const url = state.data.url;

          this.snackbar
            .open(`Order Courier Spreadsheet is ready`, 'Download', { duration: 10000 })
            .onAction()
            .subscribe(() => this.window.openNewTab(url));
        }
      });
  }

  exportCsvManifest() {
    this.view
      .mutateSelected((ids) => this.orderService.printOrderManifestCsv(ids))
      .subscribe((state) => {
        if (state.isSuccessState()) {
          const url = state.data.url;

          this.snackbar
            .open(`Order Manifest is ready`, 'Download', { duration: 10000 })
            .onAction()
            .subscribe(() => this.window.openNewTab(url));
        }
      });
  }

  exportRoyalMailSpreadsheet(format?: string) {
    this.view
      .mutateSelected((ids) => this.orderService.printRoyalMailSpreadsheet({ ids }, format))
      .subscribe((state) => {
        if (state.isSuccessState()) {
          const url = state.data.url;

          this.snackbar
            .open(`Royal Mail CSV Export is ready`, 'Download', { duration: 10000 })
            .onAction()
            .subscribe(() => this.window.openNewTab(url));
        }
      });
  }

  exportPackFleetSpreadsheet(format?: string) {
    this.view
      .mutateSelected((ids) => {
        return this.orderService.printPackFleetSpreadsheet({ ids }, format);
      })
      .subscribe((state) => {
        if (!state.isSuccessState()) return;
        const url = state.data.url;
        this.snackbar
          .open(`PackFleet export ready`, 'Download', { duration: 10000 })
          .onAction()
          .subscribe(() => this.window.openNewTab(url));
      });
  }

  async updateShippingAddress(orderId: number, address: AddressInput) {
    const result = await firstValueFrom(
      this.addressDialogService
        .openAddressInputDialog({ value: address, title: 'Update Order Address' })
        .afterClosed(),
    );

    if (!result) return;

    this.view
      .mutateRow(orderId, (orderId) =>
        this.orderService.assignOrderAddress({
          assignment: AddressAssignment.Shipping,
          orderId,
          address: result.value,
        }),
      )
      .subscribe((result) => {
        if (result.isSuccessState()) {
          this.snackbar.open(`Order address updated`, 'OK', { duration: 5000 });
        }
      });
  }
}
