import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { DataView } from '@x/common/data';
import { CartObject, IOrderDetailObject } from '@x/ecommerce/domain-client';
import { OrderActionsService } from '../../services/order-actions.service';

export type InfoPanelDisplayMode = 'visible' | 'hidden';
export type ModifiableInfoPanelDisplayMode = 'readonly' | InfoPanelDisplayMode;

@Component({
  selector: 'x-order-info-panel',
  templateUrl: './order-info-panel.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class OrderInfoPanelComponent implements OnInit, OnDestroy {
  @Input() view: DataView<IOrderDetailObject, number>;

  @Input() showChannel = true;
  @Input() showOrderMenu = true;

  @Input() orderDetails: InfoPanelDisplayMode = 'visible';
  @Input() orderInfo: InfoPanelDisplayMode = 'hidden';
  @Input() orderItemsInfo: InfoPanelDisplayMode = 'hidden';
  @Input() customerInfo: ModifiableInfoPanelDisplayMode = 'hidden';
  @Input() shippingAddressInfo: ModifiableInfoPanelDisplayMode = 'hidden';
  @Input() couponReferrerInfo: ModifiableInfoPanelDisplayMode = 'hidden';
  @Input() shippingSlotInfo: ModifiableInfoPanelDisplayMode = 'hidden';
  @Input() giftOptionsInfo: ModifiableInfoPanelDisplayMode = 'hidden';
  @Input() packingInstructionsInfo: ModifiableInfoPanelDisplayMode = 'hidden';
  @Input() checkoutRequirementsInfo: InfoPanelDisplayMode = 'hidden';
  @Input() fulfilmentInfo: ModifiableInfoPanelDisplayMode = 'hidden';
  @Input() shipmentInfo: ModifiableInfoPanelDisplayMode = 'hidden';
  @Input() paymentInfo: ModifiableInfoPanelDisplayMode = 'hidden';
  @Input() promotionInfo: InfoPanelDisplayMode = 'hidden';
  @Input() stockInfo: InfoPanelDisplayMode = 'hidden';
  @Input() adjustmentInfo: ModifiableInfoPanelDisplayMode = 'hidden';
  @Input() voucherInfo: ModifiableInfoPanelDisplayMode = 'hidden';
  @Input() subscriptionInfo: InfoPanelDisplayMode = 'hidden';
  @Input() sageInfo: InfoPanelDisplayMode = 'hidden';

  trackById = (i: number, item: { id: number }) => item.id;

  constructor(public readonly orderActions: OrderActionsService) {}

  ngOnInit(): void {
    this.view.connect();
  }
  ngOnDestroy(): void {
    this.view.disconnect();
  }

  getTotalItemUnitCount(order: CartObject) {
    return order.items.reduce((n, i) => n + i.units.filter((u) => !!u.stockLotRef).length, 0);
  }

  hasAdjustments(order: CartObject) {
    return order.adjustments.length > 0 || order.items.some((i) => i.adjustments.length > 0);
  }

  getAdjustmentCount(order: CartObject) {
    return order.adjustments.length + order.items.reduce((sum, i) => sum + i.adjustments.length, 0);
  }
}
