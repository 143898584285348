import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { NavigationEnd, NavigationSkipped, Router } from '@angular/router';
import { DataViewFactoryService } from '@x/common/data';
import { AuthContextService } from '@x/ecommerce-admin/app/auth/services/auth-context.service';
import { OrderIncidentRowCollectionProvider } from '@x/ecommerce/domain-data';
import { OrderIncidentApprovalState } from '@x/schemas/ecommerce';
import { Subject, filter, repeat, takeUntil, tap, timer } from 'rxjs';
import { ORDER_INCIDENT_TABLE_VIEW_OPTIONS } from '../order-incident-table/order-incident-table-options';

@Component({
  selector: 'x-order-incident-overlay',
  templateUrl: './order-incident-overlay.component.html',
})
export class OrderIncidentOverlayComponent implements OnInit, OnDestroy {
  private readonly _destroy$ = new Subject<void>();

  @ViewChild('approvalDialogTemplate', { static: true }) approvalDialogTemplate!: TemplateRef<any>;

  readonly view = this.dataViewFactory.resolveTableView(OrderIncidentRowCollectionProvider, {
    ...ORDER_INCIDENT_TABLE_VIEW_OPTIONS,
    id: 'order_incident_overlay',
    displayOptions: {
      showQuickView: false,
    },
    filterFixture: () => ({
      approvalStates: [OrderIncidentApprovalState.Pending],
      approverUserId: this.authContext.currentUserId,
    }),
  });

  private dialogRef?: MatDialogRef<any>;

  constructor(
    private readonly dialog: MatDialog,
    private readonly router: Router,
    private readonly dataViewFactory: DataViewFactoryService,
    public authContext: AuthContextService,
  ) {}

  ngOnInit(): void {
    if (!this.authContext.currentUserHasPermissions(['order_incidents_read'])) {
      return;
    }

    timer(1000 * 60 * 5)
      .pipe(
        takeUntil(this._destroy$),
        tap(() => this.view.refresh()),
        repeat(),
      )
      .subscribe();

    this.router.events
      .pipe(
        takeUntil(this._destroy$),
        filter((e) => e instanceof NavigationEnd || e instanceof NavigationSkipped),
        tap(() => this.dialogRef?.close()),
      )
      .subscribe();

    this.view.connect();
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
    this.view.disconnect();
  }

  openApprovalDialog(): void {
    this.view.refresh();
    this.dialogRef = this.dialog.open(this.approvalDialogTemplate, {
      minHeight: '50vh',
      height: '75vh',
      width: '80vw',
    });
  }
}
